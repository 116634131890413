import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Dialog wrap=function",
        "componentName": "Dialog",
        "wrap": "function"
      }}>{`() => {
  const [dialogShown, setDialogShown] = React.useState(false);

  return (
    <>
      <Button onClick={() => setDialogShown(true)}>Open dialog</Button>
      <Dialog show={dialogShown} onClose={() => setDialogShown(false)}>
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean varius
          euismod semper. Nulla lorem lorem, aliquam non neque in, dictum
          eleifend tortor. Suspendisse potenti. Quisque luctus vel ante quis
          vulputate. Nulla a iaculis tellus. Quisque quis neque eu nisi euismod.
        </Paragraph>
      </Dialog>
    </>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <Props identifier="Dialog" mdxType="Props" />
    <h2 {...{
      "id": "header-hidden",
      "style": {
        "position": "relative"
      }
    }}>{`Header hidden`}</h2>
    <p><inlineCode parentName="p">{`default: false`}</inlineCode></p>
    <p>{`If you don't want a header at the top of the dialog, you can set the
`}<inlineCode parentName="p">{`headerHidden`}</inlineCode>{` prop. Hiding the header, will also remove the `}<inlineCode parentName="p">{`x`}</inlineCode>{` button that can
be used to close the modal. If you want an uncloseable modal, you can combine
this with `}<inlineCode parentName="p">{`closeOnBackdropClick`}</inlineCode>{` and `}<inlineCode parentName="p">{`closeOnEscape`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Dialog wrap=function",
        "componentName": "Dialog",
        "wrap": "function"
      }}>{`() => {
  const [dialogShown, setDialogShown] = React.useState(false);

  return (
    <>
      <Button onClick={() => setDialogShown(true)}>
        Open dialog without header
      </Button>
      <Dialog
        show={dialogShown}
        onClose={() => setDialogShown(false)}
        headerHidden={true}
      >
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean varius
          euismod semper. Nulla lorem lorem, aliquam non neque in, dictum
          eleifend tortor. Suspendisse potenti. Quisque luctus vel ante quis
          vulputate. Nulla a iaculis tellus. Quisque quis neque eu nisi euismod.
        </Paragraph>
      </Dialog>
    </>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "footer",
      "style": {
        "position": "relative"
      }
    }}>{`Footer`}</h2>
    <p>{`If you want a footer at the bottom of the dialog, you can set the `}<inlineCode parentName="p">{`footer`}</inlineCode>{` prop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Dialog wrap=function",
        "componentName": "Dialog",
        "wrap": "function"
      }}>{`() => {
  const [dialogShown, setDialogShown] = React.useState(false);

  return (
    <>
      <Button onClick={() => setDialogShown(true)}>
        Open dialog with footer
      </Button>
      <Dialog
        show={dialogShown}
        onClose={() => setDialogShown(false)}
        footer={
          <>
            <Button
              size="small"
              appearance="secondary"
              onClick={() => setDialogShown(false)}
            >
              Decline
            </Button>
            <Button size="small" onClick={() => alert('Accept')}>
              Accept
            </Button>
          </>
        }
      >
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean varius
          euismod semper. Nulla lorem lorem, aliquam non neque in, dictum
          eleifend tortor. Suspendisse potenti. Quisque luctus vel ante quis
          vulputate. Nulla a iaculis tellus. Quisque quis neque eu nisi euismod.
        </Paragraph>
      </Dialog>
    </>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "ways-to-close",
      "style": {
        "position": "relative"
      }
    }}>{`Ways to close`}</h2>
    <p><inlineCode parentName="p">{`closeOnBackdropClick`}</inlineCode>{` `}<inlineCode parentName="p">{`default: true`}</inlineCode></p>
    <p><inlineCode parentName="p">{`closeOnEscape`}</inlineCode>{` `}<inlineCode parentName="p">{`default: true`}</inlineCode></p>
    <p>{`By default, the dialog can be closed by pressing the `}<inlineCode parentName="p">{`x`}</inlineCode>{` button, by hitting the
`}<inlineCode parentName="p">{`escape`}</inlineCode>{` key or by clicking the backdrop. You can granularly disable these
options.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Dialog wrap=function",
        "componentName": "Dialog",
        "wrap": "function"
      }}>{`() => {
  const [dialogShown, setDialogShown] = React.useState(false);

  return (
    <>
      <Button onClick={() => setDialogShown(true)}>
        Open dialog and only close it with the button
      </Button>
      <Dialog
        show={dialogShown}
        onClose={() => setDialogShown(false)}
        closeOnBackdropClick={false}
        closeOnEscape={false}
        lockBodyOnScroll={false}
      >
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean varius
          euismod semper. Nulla lorem lorem, aliquam non neque in, dictum
          eleifend tortor. Suspendisse potenti. Quisque luctus vel ante quis
          vulputate. Nulla a iaculis tellus. Quisque quis neque eu nisi euismod.
        </Paragraph>
      </Dialog>
    </>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "lock-body-on-scroll",
      "style": {
        "position": "relative"
      }
    }}>{`Lock body on scroll`}</h2>
    <p><inlineCode parentName="p">{`default: true`}</inlineCode></p>
    <p>{`When a Dialog is open, scrolling the body is disabled. If you want a user to
still be able to scroll the body when a Dialog is open, you can disable this
prop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Dialog wrap=function",
        "componentName": "Dialog",
        "wrap": "function"
      }}>{`() => {
  const [dialogShown, setDialogShown] = React.useState(false);

  return (
    <>
      <Button onClick={() => setDialogShown(true)}>
        Open dialog and keep scrolling
      </Button>
      <Dialog
        show={dialogShown}
        onClose={() => setDialogShown(false)}
        lockBodyOnScroll={false}
      >
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean varius
          euismod semper. Nulla lorem lorem, aliquam non neque in, dictum
          eleifend tortor. Suspendisse potenti. Quisque luctus vel ante quis
          vulputate. Nulla a iaculis tellus. Quisque quis neque eu nisi euismod.
        </Paragraph>
      </Dialog>
    </>
  );
};
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      